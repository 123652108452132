const facebook = (function() {
  return {
    login: (callback) => {
      FB.login(function (response) {
        FB.api("/me?fields=id,name,email", function (response) {
            let data = {
                facebook: response.id,
                email: response.email,
                name: response.name
            }
            if (data.facebook && data.name && data.email) {
              $.ajax({
                  url: SITE_URL + '/fb',
                  data,
                  method: "POST",
                  success: function (response, textStatus, xhr) {
                      if (xhr.status === 200) {
                        callback(response, textStatus, xhr);
                      } else {
                        callback(response, textStatus, xhr);
                      }
                  },
                  error: function (error) {
                      console.log(error)
                  },
                  statusCode: {
                      400: function() {
                          window.alert("Ezzel az email címmel már létezik felhasználói fiók!");
                      }
                  }
                  
              })
            }
        });
    }, {
            scope: 'public_profile, email',
            return_scopes: true
        });
    },
    init: () => {
        
        (function(d, s, id) {
            let appid = CONFIG.env !== 'production' ? '1641736835879884' : '564282320621794';
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = `//connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v2.10&appId=${appid}`;
            fjs.parentNode.insertBefore(js, fjs);
            $('.btn-fb').removeClass("faded")
        }(document, 'script', 'facebook-jssdk'));
    }
  }
})()

export default facebook;