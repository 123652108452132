import facebook from '../helpers/facebook';
//import Cart from '../globalCart/Cart';
import "../../css/login.css";

const login = (function() {
  //let _cart = null;

  function _setupListeners() {
    $('.btn-fb').click(function() {
      facebook.login(function(result) {
        result = JSON.parse(result);

        if (result.success) {
          window.location.href = SITE_URL
        } else if (result.error) {
          window.alert(result.error)
        }
      })
    })

    $('[name=email], [name=username]').on('input', function() {
      $(this).val($(this).val().replace(/[^a-z0-9@_.\- ]/gi, ''));
    });
  }

  return {
    init: () => {
      console.log('init')
      facebook.init();
      _setupListeners();
      //_cart = new Cart();
    }
  }
})()

export default login;